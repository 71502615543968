import { theme } from '../../theme';
import { SxProps, Theme, Typography, Box, Avatar, CircularProgress } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { LogoutComponent } from '../common/LogoutComponent';
import coin from '../../assets/icons/coin.svg';
import leaderboard from '../../assets/icons/leaderboard.svg';
import achievements from '../../assets/icons/achievements.svg';
import communitywall from '../../assets/icons/communitywall.svg';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { LeaderBoardUserItem } from '@yellow-owl/client-sdk';

const contentStyle: SxProps<Theme> = {
  fontFamily: 'CormorantGaramondRegular',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '16px',
};

interface mockDetails {
  name: string;
  coins: number;
  avatar: string;
  position: number;
}

interface LeaderBoardUserDetails {
  userName: string;
  userScore: number;
}

interface LeaderBoardPageProps {
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
  startDate: string;
  endDate: string;
  grade: string;
  isLoading: boolean;
  hasError: boolean;
  isEmpty: boolean;
  leaderBoardUserDetails: LeaderBoardUserDetails;
  userData: LeaderBoardUserItem[];
  acquiredBadges: Badge[];
  badgesToGet: Badge[];
}

interface Badge {
  type: string;
  badgeUrl: string;
  description: string;
}

interface Top3StudentPositionCardProps {
  avatar: string;
  name: string;
  coins: number;
  position: number;
  heightOfBar: string;
  primaryColorBar: string;
  secondaryColorBar: string;
}

interface IndividualLeaderboardPositionCardProps {
  position: number;
  avatar: string;
  name: string;
  coins: number;
}

interface MenuItemProps {
  image: string;
  title: string;
  isBold: boolean;
}

interface AchievementsProps {
  acquiredBadges: Badge[];
  badgesToGet: Badge[];
}

interface SkillProgressProps {
  skillName: string;
  totalActivities: number;
  completedActivies: number;
  color: string;
}

interface BadgeProps {
  badge: Badge;
}

const leaderboardLeftContainer = {
  width: 'auto',
  height: 'auto',
  flex: '1',
  padding: '20px',
  margin: '20px',
  bgcolor: '#fefbdd',
  borderRadius: '16px',
  boxSizing: 'border-box',
  overflowY: 'none',
};

const leaderboardRightContainer = {
  width: 'auto',
  height: 'auto',
  flex: '2',
  padding: '20px',
  margin: '20px 20px 20px 0',
  bgcolor: '#fdf7ba',
  borderRadius: '16px',
  position: 'relative',
  overflowY: 'auto',
  boxSizing: 'border-box',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
};

const scrollableRanksContainer = {
  maxHeight: 'calc(80vh - 200px)',
  overflowY: 'auto',
  boxSizing: 'border-box',
  paddingBottom: '20px',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

const badgesContainer = {
  bgcolor: 'white',
  borderRadius: '15px',
  p: '5px',
  boxShadow: '6px 6px 10px rgba(217, 217, 217, 1)',
  display: 'flex',
  overflowX: 'auto',
  width: '19.5vw',
  ml: '1vw',
  mb: '1vw',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

const badgeStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: '0 0 auto',
  scrollSnapAlign: 'start',
  margin: '0 5px',
};

const badgeIconStyle = {
  width: '45px',
  height: '45px',
  marginBottom: '5px',
};

const LeaderBoardToggleButtons = {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignItems: 'center',
  width: '100%', // Full width to avoid overflow
  height: 'auto', // Adjust height to content
  position: 'sticky', // Keep it in view within its parent
  top: '0', // Stick to the top of its parent
  zIndex: 1, // Ensure it appears above other content
  overflow: 'visible', // Ensure no internal scrolling
};

const loaderAndErrorMessageCard = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '150px',
};

const DateSelectorButtonGroup = {
  fontFamily: 'RalewayRegular',
  fontSize: '14px',
  bgcolor: '#746cae',
  borderRadius: '24px',
  height: '90%',
  color: 'white',
  margin: '0',
  overflow: 'hidden',
};

const GradeSelector = {
  fontFamily: 'RalewayRegular',
  fontSize: '14px',
  fontWeight: 'bold',
  bgcolor: '#746cae',
  borderRadius: '24px',
  height: '90%',
  color: 'white',
};

const studentPostionCardStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  textAlign: 'center',
  position: 'sticky',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
  },
  marginTop: '-45px',
};

const studentPostionCardContainerStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  ml: '10%',
  width: '60%',
  position: 'sticky',
  top: '20px',
  mb: '30px',
  backgroundColor: '#fdf7ba',

  [theme.breakpoints.down('sm')]: {
    ml: '0',
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'space-between',
  },
};

const innerContainerStyles = {
  width: '100%',

  height: '95vh',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  bgcolor: '#ffffff',
  borderRadius: '16px',
  overflow: 'hidden',
  maxWidth: '100vw',
  boxSizing: 'border-box',
};

const toggleButtonStyles = {
  fontFamily: 'RalewayRegular',
  textTransform: 'none',
  fontSize: '14px',
  backgroundColor: 'white',
  color: '#746CAE',
  margin: '0',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: '#746CAE',
    color: 'white',
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: '#746CAE',
    overflow: 'hidden',
    margin: '0',
  },
};

const scrollableBadgesStyles = {
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: '200px',
  padding: '10px 0',
  '&::-webkit-scrollbar': {
    width: '0',
  },
  scrollbarWidth: 'none',
};

const mainLeaderBoardContainerStyles = {
  margin: ' auto',
  height: 'auto',
  width: 'auto',
  p: 2,
  bgcolor: '#FBEC53',
};

const LeftContainerTopUserBox = {
  mr: '55px',
  mb: '15px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const LeftContainerTopUserAvatarStyles = {
  width: '90px',
  height: '90px',
  bgcolor: 'lightblue',
  borderRadius: '50%',
};

const ToggleButtonGroupStyles = {
  fontSize: '14px',
  margin: '0',
  overflow: 'hidden',
};

const MenuItemLeftStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '90%',
  mb: '15px',
};

const MenuItemLeftIcon = {
  display: 'flex',
  flexDirection: 'row',
};

const IndividualLeaderboardPositionCardStyles = {
  display: 'flex',
  flexDirection: 'row',
  ml: '4vw',
  mb: '3vh',
  justifyContent: 'space-between',
  width: '60%',
  [theme.breakpoints.down('sm')]: {
    width: '60vw',
    ml: '1vw',
  },
};

const IndividualLeaderboardPositionAvatarStyles = {
  bgcolor: 'lightblue',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '35px',
  height: '35px',
};

const IndividualLeaderboardPositionTextStyles = {
  display: 'flex',
  flexDirection: 'column',
  px: '8px',
};

const IndividualLeaderboardPositionUserPositionStyles = {
  bgcolor: '#f5b452',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '20px',
};

const IndividualLeaderboardPositionAvatarWidth = {
  width: '30px',
  height: '30px',
};

const IndividualLeaderboardPositionCardsOrientation = {
  display: 'flex',
  flexDirection: 'row',
};

export const MenuItemLeft: React.FC<MenuItemProps> = ({ image, title, isBold }) => {
  return (
    <Box sx={MenuItemLeftStyles}>
      <Box sx={MenuItemLeftIcon}>
        <img src={image} alt={image} />
        <Typography
          sx={{
            fontWeight: isBold ? 'bold' : 'normal',
            fontFamily: 'RalewayRegular',
            display: 'flex',
            alignItems: 'center',
            px: '10px',
            fontSize: '16px',
          }}
          variant='h6'
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <KeyboardArrowRightIcon />
      </Box>
    </Box>
  );
};

export const Top3StudentPositionCard: React.FC<Top3StudentPositionCardProps> = ({
  avatar,
  name,
  coins,
  position,
  heightOfBar,
  primaryColorBar,
  secondaryColorBar,
}) => {
  return (
    <Box sx={studentPostionCardStyles}>
      <Avatar
        sx={{
          display: 'flex',
          alignSelf: 'center',
          bgcolor: 'lightblue',
          borderRadius: '50%',
          height: '50px',
          width: '50px',
        }}
        alt={name}
        src={avatar}
      />
      <Typography sx={{ fontWeight: 'bold', fontFamily: 'RalewayRegular', fontSize: '16px' }}>{name}</Typography>
      <Typography sx={{ fontFamily: 'RalewayRegular', fontSize: '12px' }}>
        {coins} <img src={coin} alt={coin} style={{ width: '17px', height: '17px' }} />
      </Typography>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: '100%', marginTop: '10px' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: primaryColorBar,
            width: '120px',
            [theme.breakpoints.down('sm')]: {
              alignSelf: 'center',
              width: '50px',
            },
            height: heightOfBar,
            borderRadius: '12px 12px 0 0',
          }}
        >
          <Box
            sx={{
              bgcolor: secondaryColorBar,
              height: '40px',
              width: '40px',
              borderRadius: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography sx={{ color: 'white' }}>{position}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const IndividualLeaderboardPositionCard: React.FC<IndividualLeaderboardPositionCardProps> = ({
  position,
  avatar,
  name,
  coins,
}) => {
  return (
    <Box key={position} sx={IndividualLeaderboardPositionCardStyles}>
      <Box sx={IndividualLeaderboardPositionCardsOrientation}>
        <Box sx={IndividualLeaderboardPositionAvatarStyles}>
          <Avatar sx={IndividualLeaderboardPositionAvatarWidth} src={avatar} />
        </Box>
        <Box sx={IndividualLeaderboardPositionTextStyles}>
          <Typography sx={{ fontWeight: 'bold', fontFamily: 'RalewayRegular', fontSize: '14px' }}>{name}</Typography>
          <Typography sx={{ fontFamily: 'RalewayRegular', fontSize: '12px' }}>
            {coins} <img src={coin} alt={coin} style={{ width: '16px', height: '16px' }} />
          </Typography>
        </Box>
      </Box>
      <Box sx={IndividualLeaderboardPositionUserPositionStyles}>
        <Typography sx={{ fontFamily: 'RalewayRegular', fontSize: '20px', color: 'white' }}>{position}</Typography>
      </Box>
    </Box>
  );
};

export const LeaderBoardPage: React.FC<LeaderBoardPageProps> = ({
  searchParams,
  setSearchParams,
  // startDate,
  // endDate,
  grade,
  isLoading,
  hasError,
  isEmpty,
  leaderBoardUserDetails,
  userData,
  acquiredBadges,
  badgesToGet,
}) => {
  const [selectedButton, setSelectedButton] = useState('today');
  const [showAchievements, setShowAchievements] = useState(false);
  const [showLeaderBoard, setShowLeaderBoard] = useState(true);
  const [showMyProgress, setShowMyProgress] = useState(false);
  const navigate = useNavigate();
  const [clickedIndex, setClickedIndex] = useState<number>(0);

  const menuItemStyles = (index: number) => ({
    color: '#625B77',
    display: 'flex',
    fontWeight: clickedIndex === index ? 'bold' : 'normal',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '22vw',
    pl: '1vw',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      width: '60vw',
    },
  });

  const handleMenuItemClick = (index: number, handleClick: () => void) => {
    setClickedIndex(index);
    handleClick();
  };

  const handleAchievementsClick = () => {
    setShowAchievements(true);
    setShowLeaderBoard(false);
    setShowMyProgress(false);
  };

  const handleLeaderboardClick = () => {
    setShowLeaderBoard(true);
    setShowAchievements(false);
    setShowMyProgress(false);
  };

  const handleCommunityWallClick = () => {
    navigate('/community-wall');
  };

  const handleMyProgressClick = () => {
    setShowMyProgress(true);
    setShowLeaderBoard(false);
    setShowAchievements(false);
  };

  // const handleGradeChange = (event: SelectChangeEvent<string>) => {
  //   const newGrade = event.target.value;
  //   setSearchParams({ grade: newGrade });
  // };

  const handleDateChange = (event: any, newSelection: any) => {
    if (newSelection === null) return;
    else {
      const today = new Date();
      let startDate = '';
      let endDate = '';

      switch (newSelection) {
        case 'today': {
          startDate = format(new Date(), 'dd-MM-yyyy');
          const updatedParams = new URLSearchParams(searchParams.toString());
          updatedParams.set('startDate', startDate);
          updatedParams.set('endDate', startDate);
          setSearchParams(updatedParams);
          break;
        }
        case 'thisWeek': {
          const dayOfWeek = today.getDay();
          const startOfWeek = new Date(today);
          startOfWeek.setDate(today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));

          startDate = format(startOfWeek, 'dd-MM-yyyy');
          endDate = format(today, 'dd-MM-yyyy');
          const updatedParams = new URLSearchParams(searchParams.toString());
          updatedParams.set('startDate', startDate);
          updatedParams.set('endDate', endDate);
          setSearchParams(updatedParams);
          break;
        }
        case 'thisMonth': {
          const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
          const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

          startDate = format(startOfMonth, 'dd-MM-yyyy');
          endDate = format(endOfMonth, 'dd-MM-yyyy');
          const updatedParams = new URLSearchParams(searchParams.toString());
          updatedParams.set('startDate', startDate);
          updatedParams.set('endDate', endDate);
          setSearchParams(updatedParams);
          break;
        }
        default:
          break;
      }
      setSelectedButton(newSelection);
    }
  };

  return (
    <Box sx={contentStyle}>
      <Box sx={mainLeaderBoardContainerStyles}>
        <Box sx={innerContainerStyles}>
          <Box sx={leaderboardLeftContainer}>
            {isLoading ? (
              <Box sx={loaderAndErrorMessageCard}>
                <CircularProgress />
              </Box>
            ) : hasError ? (
              <Box sx={loaderAndErrorMessageCard}>
                <Typography>Oops! we have an error! Try again.</Typography>
              </Box>
            ) : isEmpty ? (
              <Box sx={loaderAndErrorMessageCard}>
                <Typography>Oops! No data found! Try again.</Typography>
              </Box>
            ) : (
              <>
                {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: '7vw' }}>
                  {<ModeEditOutlineOutlinedIcon />}
                </Box> */}
                <Box sx={LeftContainerTopUserBox}>
                  <Avatar alt={userData[0].name} src={userData[0].avatar} sx={LeftContainerTopUserAvatarStyles} />
                  <Typography sx={{ fontWeight: 'bold', fontFamily: 'RalewayRegular', fontSize: '20px' }} variant='h6'>
                    {leaderBoardUserDetails?.userName}
                  </Typography>
                  <Typography
                    sx={{ display: 'flex', alignItems: 'center', fontFamily: 'RalewayRegular', fontSize: '16px' }}
                  >
                    {leaderBoardUserDetails?.userScore}
                    <img src={coin} alt={coin} style={{ width: '17px', height: '17px' }} />
                  </Typography>
                </Box>
              </>
            )}
            <Box sx={badgesContainer}>
              {acquiredBadges?.map((badge: any, index: number) => (
                <Box sx={badgeStyle} key={index}>
                  <img src={badge.badgeUrl} alt={badge.type} style={badgeIconStyle} />
                </Box>
              ))}
            </Box>
            <Box sx={menuItemStyles(0)} onClick={() => handleMenuItemClick(0, handleLeaderboardClick)}>
              <MenuItemLeft title='Leaderboard' image={leaderboard} isBold={clickedIndex === 0} />
            </Box>
            <Box sx={menuItemStyles(1)} onClick={() => handleMenuItemClick(1, handleCommunityWallClick)}>
              <MenuItemLeft title='Community Wall' image={communitywall} isBold={clickedIndex === 1} />
            </Box>
            <Box sx={menuItemStyles(2)} onClick={() => handleMenuItemClick(2, handleAchievementsClick)}>
              <MenuItemLeft title='Achievements' image={achievements} isBold={clickedIndex === 2} />
            </Box>
            <Box sx={menuItemStyles(3)} onClick={() => handleMenuItemClick(3, handleMyProgressClick)}>
              <MenuItemLeft title='My Progress' image={leaderboard} isBold={clickedIndex === 3} />
            </Box>
            <Box sx={menuItemStyles(3)}>
              <LogoutComponent />
            </Box>
          </Box>

          {showAchievements ? (
            <Box sx={leaderboardRightContainer}>
              <Achievements acquiredBadges={acquiredBadges} badgesToGet={badgesToGet} />
            </Box>
          ) : showLeaderBoard ? (
            <Box sx={leaderboardRightContainer}>
              <Box sx={LeaderBoardToggleButtons}>
                <Box>
                  <Select sx={GradeSelector} value={grade} label='grade' /*onChange={handleGradeChange}*/>
                    <MenuItem value={'4'}>Grade 4</MenuItem>
                    <MenuItem value={'5'}>Grade 5</MenuItem>
                    <MenuItem value={'6'}>Grade 6</MenuItem>
                  </Select>
                </Box>
                <Box sx={DateSelectorButtonGroup}>
                  <ToggleButtonGroup
                    sx={ToggleButtonGroupStyles}
                    value={selectedButton}
                    exclusive
                    onChange={handleDateChange}
                    aria-label='date selection'
                  >
                    <ToggleButton sx={toggleButtonStyles} value='today' aria-label='today'>
                      Today
                    </ToggleButton>
                    <ToggleButton sx={toggleButtonStyles} value='thisWeek' aria-label='this week'>
                      This Week
                    </ToggleButton>
                    <ToggleButton sx={toggleButtonStyles} value='thisMonth' aria-label='this month'>
                      This Month
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Box>

              {isLoading ? (
                <Box sx={loaderAndErrorMessageCard}>
                  <CircularProgress />
                </Box>
              ) : hasError ? (
                <Box sx={loaderAndErrorMessageCard}>
                  <Typography>Oops! we have an error! Try again.</Typography>
                </Box>
              ) : isEmpty ? (
                <Box sx={loaderAndErrorMessageCard}>
                  <Typography>Oops! No data found! Try again.</Typography>
                </Box>
              ) : (
                <>
                  <Box sx={studentPostionCardContainerStyles}>
                    <Top3StudentPositionCard
                      key={userData[1].avatar}
                      avatar={userData[1].avatar}
                      name={userData[1].name}
                      coins={userData[1].coins}
                      position={userData[1].position}
                      heightOfBar={'90px'}
                      primaryColorBar={'#73c7d3'}
                      secondaryColorBar={'#44b1c0'}
                    />

                    <Top3StudentPositionCard
                      key={userData[0].avatar}
                      avatar={userData[0].avatar}
                      name={userData[0].name}
                      coins={userData[0].coins}
                      position={userData[0].position}
                      heightOfBar={'130px'}
                      primaryColorBar={'#746cae'}
                      secondaryColorBar={'#423984'}
                    />

                    <Top3StudentPositionCard
                      key={userData[2].avatar}
                      avatar={userData[2].avatar}
                      name={userData[2].name}
                      coins={userData[2].coins}
                      position={userData[2].position}
                      heightOfBar={'70px'}
                      primaryColorBar={'#e9bcd2'}
                      secondaryColorBar={'#b87696'}
                    />
                  </Box>
                  <Box sx={scrollableRanksContainer}>
                    {userData.slice(3)?.map((kid: mockDetails) => (
                      <IndividualLeaderboardPositionCard
                        key={kid.position}
                        avatar={kid.avatar}
                        name={kid.name}
                        coins={kid.coins}
                        position={kid.position}
                      />
                    ))}
                  </Box>
                </>
              )}
            </Box>
          ) : showMyProgress ? (
            <Box sx={leaderboardRightContainer}>
              <MyProgress />
            </Box>
          ) : (
            ''
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const MyProgress: React.FC = () => {
  return (
    <Box>
      <Box sx={{ marginLeft: '20px' }}>
        <Typography sx={{ fontFamily: 'RalewayRegular', fontSize: '18px', fontWeight: 'bold' }}>
          Your Progress so far
        </Typography>
      </Box>
      <Box>
        <SkillProgress skillName='Creativity' totalActivities={45} completedActivies={40} color='#FFC107' />
        <SkillProgress skillName='Critical Thinking' totalActivities={58} completedActivies={39} color='#607D8B' />
        <SkillProgress skillName='Communication' totalActivities={36} completedActivies={18} color='#2196F3' />
        <SkillProgress skillName='Socio-emotional' totalActivities={40} completedActivies={13} color='#9C27B0' />
        <SkillProgress skillName='Collaboration' totalActivities={29} completedActivies={11} color='#4CAF50' />
      </Box>
    </Box>
  );
};

export const SkillProgress: React.FC<SkillProgressProps> = ({
  skillName,
  totalActivities,
  completedActivies,
  color,
}) => {
  const [progress, setProgress] = useState(0);
  const targetValue = Math.round((completedActivies / totalActivities) * 100);
  const increment = 0.5;
  const duration = 500;

  useEffect(() => {
    const interval = (duration / targetValue) * increment;
    if (progress < targetValue) {
      const timer = setTimeout(() => {
        setProgress((prev) => Math.min(prev + 1, targetValue));
      }, interval);

      return () => clearTimeout(timer);
    }
  }, [progress, targetValue]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: '12px',
        padding: '16px',
        margin: '16px',
        boxShadow: 3,
      }}
    >
      <Box sx={{ position: 'relative', display: 'inline-flex', marginRight: 2 }}>
        <CircularProgress sx={{ color: { color } }} variant='determinate' value={progress} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{ fontFamily: 'RalewayRegular', fontSize: '10px', fontWeight: 'bold' }}
            variant='caption'
            component='div'
            color='textSecondary'
          >
            {`${targetValue}%`}
          </Typography>
        </Box>
      </Box>

      <Box>
        <Typography sx={{ fontFamily: 'RalewayRegular', fontSize: '16px', fontWeight: 'bold' }} variant='h6'>
          {skillName}
        </Typography>
        <Typography sx={{ fontFamily: 'RalewayRegular', fontSize: '14px' }} variant='body2'>
          You finished {completedActivies}/{totalActivities} activities under the {skillName} skill
        </Typography>
      </Box>
    </Box>
  );
};

export const BadgeComponent: React.FC<BadgeProps> = ({ badge }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', margin: '10px' }}>
      <Box>
        <img style={{ width: '44px', height: '52px' }} src={badge.badgeUrl} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', justifyContent: 'center' }}>
        <Typography sx={{ fontFamily: 'RalewayRegular', fontSize: '14px', fontWeight: 'bold' }}>
          {badge.type.charAt(0).toUpperCase() + badge.type.slice(1).toLowerCase()}
        </Typography>
        <Typography sx={{ color: '#625B77', fontFamily: 'RalewayRegular', fontSize: '14px' }}>
          {badge.description}
        </Typography>
      </Box>
    </Box>
  );
};

const Achievements: React.FC<AchievementsProps> = ({ acquiredBadges, badgesToGet }) => {
  return (
    <>
      <Box sx={{ scrollableBadgesStyles }}>
        <Typography sx={{ fontFamily: 'RalewayRegular', fontSize: '14px', fontWeight: 'bold', margin: '10px' }}>
          Badges Earned
        </Typography>
        <Box sx={{}}>
          {acquiredBadges?.map((badge: Badge) => {
            return <BadgeComponent key={badge.badgeUrl} badge={badge} />;
          })}
        </Box>
      </Box>
      <Box sx={{ marginTop: '30px' }}>
        <Typography sx={{ fontFamily: 'RalewayRegular', fontSize: '14px', fontWeight: 'bold', margin: '10px' }}>
          More Badges
        </Typography>
        <Box sx={{}}>
          {badgesToGet?.map((badge: Badge) => {
            return <BadgeComponent key={badge.type} badge={badge} />;
          })}
        </Box>
      </Box>
    </>
  );
};
