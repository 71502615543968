import { Button, IconButton, CircularProgress } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useAsync } from '../../../hooks/use-async';
import { YellowOwlContext } from '../../../context/YellowOwlContext';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { yellowOwlMessages } from '../../../Constants';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

const feedbackStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '12px',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  border: '5px solid #3C3077',
  boxShadow: '1px 2px 6px 3px #b2acd3',
  p: 4,
  fontFamily: 'Schoolbell',
  fontWeight: '500',
};
const feedbackHeader = {
  fontFamily: 'Schoolbell',
  fontWeight: '900',
  fontSize: '1.7rem',
  margin: '10px 5px',
};

const ratingStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  span: {
    background: 'white',
    cursor: 'pointer',
    margin: '10px 0px',
    width: '80px',
    height: '80px',
    fontSize: '4rem',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    border: '3px solid #3C3077',
    boxShadow: '1px 2px 6px 3px #b2acd3',
  },
  p: {
    fontSize: '1.2rem',
    fontWeight: '700',
    marginTop: '10px',
  },
};
const emojiStyles = {
  width: '31%',
  height: '150px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};

const iamDoneStyles = {
  fontSize: '20px',
  border: '4px solid #3C3077',
  borderRadius: '16px',
};

function MagazineRating(props: MagazineRatingProps) {
  const {
    userId,
    magazineId,
    activityId,
    tenantId,
    categoryId,
    canShowFeedbackModal,
    onCheckMandatory,
    onCloseFeedbackSubmitModal,
  } = props;

  const [ratingData, setRatingData] = useState<number | undefined>(undefined);
  const yellowOwlApi = useContext(YellowOwlContext)!;

  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const hasShownSuccessToast = useRef(false);
  const hasShownErrorToast = useRef(false);

  const queryParams = getQueryParams(location.search);
  const id = queryParams.get('id');

  const navigateToTableOfContents = () => {
    navigate({
      pathname: '/magazine/index',
      search: `?id=${id}`,
    });
  };

  const handleClose = () => {
    onCloseFeedbackSubmitModal();
  };

  const handleEmojiClick = (newRating: number) => {
    setRatingData(newRating);
  };

  const [addUserFeedbackResult, addUserFeedback] = useAsync({
    fn: async () => {
      if (ratingData && ratingData > 0) {
        const result = await yellowOwlApi.addUserFeedback({
          userId: +userId,
          magazineId: +magazineId,
          activityId: +activityId,
          rating: ratingData as any,
          tenantId: tenantId,
          categoryId: categoryId,
          deviceType: 'web',
        });
        return result.data;
      }
    },
  });

  const [, addUserTimeSpent] = useAsync({
    fn: async () => {
      const key = `${userId}_${magazineId}_${activityId}`;
      const storedTimeSpent = localStorage.getItem('timeSpent');
      const timeSpentData = storedTimeSpent ? JSON.parse(storedTimeSpent) : {};
      const userTimeSpent = timeSpentData[key] ? timeSpentData[key] : 0;

      const result = await yellowOwlApi.addUserTimeSpent({
        userId: +userId,
        magazineId: +magazineId,
        activityId: +activityId,
        tenantId: tenantId,
        categoryId: categoryId,
        timeSpent: +userTimeSpent,
      });
      return result.data;
    },
  });

  useEffect(() => {
    if (ratingData && ratingData > 0) {
      addUserFeedback();
      addUserTimeSpent();
    }
  }, [ratingData]);

  useEffect(() => {
    if (addUserFeedbackResult.result?.message && !hasShownSuccessToast.current) {
      toast.success('Thanks for your feedback');
      onCloseFeedbackSubmitModal();
      hasShownSuccessToast.current = true;
      navigateToTableOfContents();
    }
  }, [addUserFeedbackResult.result]);

  useEffect(() => {
    if (addUserFeedbackResult.error && !hasShownErrorToast.current) {
      // TODO: show error message and still show the information
      onCloseFeedbackSubmitModal();
      hasShownErrorToast.current = true; // Mark the error toast as shown
    }
  }, [addUserFeedbackResult.error, onCloseFeedbackSubmitModal]);

  const emojis = [
    { rating: 1, emoji: '😑', label: yellowOwlMessages.ITS_OKAY },
    { rating: 2, emoji: '🙂', label: yellowOwlMessages.ITS_GOOD },
    { rating: 3, emoji: '🥳', label: yellowOwlMessages.AMAZING },
  ];

  return (
    <div>
      <Button onClick={onCheckMandatory} variant='outlined' sx={iamDoneStyles}>
        {yellowOwlMessages.IAM_DONE}
      </Button>
      <Modal
        open={canShowFeedbackModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={feedbackStyle}>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
            <CloseIcon />
          </IconButton>
          <Typography variant='h5' sx={feedbackHeader}>
            {yellowOwlMessages.HOW_DID_YOU_LIKE_THE_ACTIVITY}
          </Typography>
          {addUserFeedbackResult.isLoading ? (
            <Box display='flex' justifyContent='center' alignItems='center' minHeight='150px'>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={ratingStyles}>
              {emojis.map(({ rating, emoji, label }) => (
                <Box key={rating} sx={emojiStyles} onClick={() => handleEmojiClick(rating)}>
                  <span>{emoji}</span>
                  <p>{label}</p>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}

interface MagazineRatingProps {
  userId: string;
  magazineId: string;
  activityId: string;
  tenantId: number;
  categoryId: number;
  canShowFeedbackModal: boolean;
  onCheckMandatory: () => void;
  onCloseFeedbackSubmitModal: () => void;
  rating?: 1 | 2 | 3;
}

export default MagazineRating;
