import { useContext, useEffect, useMemo, useState } from 'react';
import { LeaderBoardPage } from '../../components/walkthrough/LeaderBoardPage';
import { YellowOwlContext } from '../../context/YellowOwlContext';
import { useSearchParams } from 'react-router-dom';
import { COUNTRY_CODE_KEY, useLocalStorage } from '../../context/LocalStorageContext';
import { getCodeForCountry } from '../../utils/commonUtils';
import { LeaderBoardUserItem } from '@yellow-owl/client-sdk/src/api';

interface mockDetails {
  name: string;
  coins: number;
  avatar: string;
  position: number;
}

interface AcquiredBadges {
  type: string;
  badgeUrl: string;
  description: string;
}
[];

interface LeaderBoardUserDetails {
  userName: string;
  userScore: number;
}

export const AcquiredBadges = [
  {
    type: 'ONTIME',
    badgeUrl: 'https://yowl-2024-prod.s3.ap-south-1.amazonaws.com/On_time_badge.svg',
    description: 'Yay! You have been on time, completing three magazines within 3 days of publishing.',
  },
  {
    type: 'CONSISTENT',
    badgeUrl: 'https://yowl-2024-prod.s3.ap-south-1.amazonaws.com/Consistent_badge.svg',
    description: 'Amazing effort! You have been very persistent and completed 12 magazines!',
  },
  {
    type: 'ACTIVE_PARTICIPANT',
    badgeUrl: 'https://yowl-2024-prod.s3.ap-south-1.amazonaws.com/Active_participant_badge.svg',
    description:
      'Thank you for sharing your creative works with your friends and inspiring them through your active participation in discussions and activities!',
  },
  {
    type: 'INNOVATOR',
    badgeUrl: 'https://yowl-2024-prod.s3.ap-south-1.amazonaws.com/Innovator_badge.svg',
    description:
      'Congratulations! You completed 20 activities tagged under creativity skill, showcasing your innovative mindset.',
  },
  {
    type: 'COLLABORATOR',
    badgeUrl: 'https://yowl-2024-prod.s3.ap-south-1.amazonaws.com/collaborate_badge.svg',
    description:
      "Great teamwork! You've collaborated on 20 activities, demonstrating your ability to work well with others.",
  },
  {
    type: 'STORYTELLER',
    badgeUrl: 'https://yowl-2024-prod.s3.ap-south-1.amazonaws.com/Story_teller_badge.svg',
    description:
      'You achieved the Storyteller badge by completing 20 activities tagged under communication skill, sharing your unique stories with others.',
  },
];

export const BadgesToGet = [
  {
    type: 'LITTLE_BUDDHA',
    badgeUrl: 'https://yowl-2024-prod.s3.ap-south-1.amazonaws.com/Little_Buddha_badge.svg',
    description:
      'You completed 20 activities tagged under socio-emotional skill, showcasing your wisdom and emotional intelligence.',
  },
  {
    type: 'PROBLEM_SOLVER',
    badgeUrl: 'https://yowl-2024-prod.s3.ap-south-1.amazonaws.com/Problem_solver_badge.svg',
    description:
      "Impressive! You've completed 20 activities tagged under critical thinking skill, proving your ability to solve problems creatively and effectively.",
  },
];

const mockUsers = [
  {
    name: 'Ravi Kiran',
    avatar: 'https://cdn1.iconfinder.com/data/icons/back-to-school-434/64/Student_Boy-256.png',
  },
  {
    name: 'Sai Latha',
    avatar: 'https://cdn1.iconfinder.com/data/icons/back-to-school-434/64/Student_Girl-256.png',
  },
  {
    name: 'Nikhil Jos',
    avatar: 'https://cdn1.iconfinder.com/data/icons/back-to-school-434/64/Student_Boy-256.png',
  },
  {
    name: 'Arun Kumar',
    avatar: 'https://cdn1.iconfinder.com/data/icons/back-to-school-434/64/Student_Boy-256.png',
  },
  {
    name: 'Anusha Roy',
    avatar: 'https://cdn1.iconfinder.com/data/icons/back-to-school-434/64/Student_Girl-256.png',
  },
  {
    name: 'Bavaji Khan',
    avatar: 'https://cdn1.iconfinder.com/data/icons/back-to-school-434/64/Student_Boy-256.png',
  },
  {
    name: 'Anjana Goud',
    avatar: 'https://cdn1.iconfinder.com/data/icons/back-to-school-434/64/Student_Girl-256.png',
  },
  {
    name: 'Uday Naidu',
    avatar:
      'https://cdn0.iconfinder.com/data/icons/children-avatars-1/66/14_kindergarten_boy_boy_student_young_lad_youth-512.png',
  },
  {
    name: 'Devansh Shetty',
    avatar:
      'https://cdn0.iconfinder.com/data/icons/children-avatars-1/66/14_kindergarten_boy_boy_student_young_lad_youth-512.png',
  },
  {
    name: 'Hemanth Kumar',
    avatar:
      'https://cdn0.iconfinder.com/data/icons/children-avatars-1/66/14_kindergarten_boy_boy_student_young_lad_youth-512.png',
  },
  {
    name: 'Kiran Kumar',
    avatar:
      'https://cdn0.iconfinder.com/data/icons/children-avatars-1/66/14_kindergarten_boy_boy_student_young_lad_youth-512.png',
  },
  {
    name: 'Mounika Shetty',
    avatar: 'https://cdn4.iconfinder.com/data/icons/world-children-s-day-bzzricon-flat/512/02_Student-256.png',
  },
  {
    name: 'Rohith Reddy',
    avatar:
      'https://cdn0.iconfinder.com/data/icons/children-avatars-1/66/14_kindergarten_boy_boy_student_young_lad_youth-512.png',
  },
  {
    name: 'Vishnu Priya',
    avatar: 'https://cdn4.iconfinder.com/data/icons/world-children-s-day-bzzricon-flat/512/02_Student-256.png',
  },
  {
    name: 'Lakshmi Narayana',
    avatar:
      'https://cdn0.iconfinder.com/data/icons/children-avatars-1/66/14_kindergarten_boy_boy_student_young_lad_youth-512.png',
  },
  {
    name: 'Harini Muralidhar',
    avatar: 'https://cdn4.iconfinder.com/data/icons/world-children-s-day-bzzricon-flat/512/02_Student-256.png',
  },
  {
    name: 'Srinivas Rao',
    avatar:
      'https://cdn0.iconfinder.com/data/icons/children-avatars-1/66/82_university_guy_party_boy_boy_student_home_kid_little_angle_hero-256.png',
  },
  {
    name: 'Swathi Reddy',
    avatar: 'https://cdn4.iconfinder.com/data/icons/world-children-s-day-bzzricon-flat/512/02_Student-256.png',
  },
  {
    name: 'Bhargav Sai',
    avatar:
      'https://cdn0.iconfinder.com/data/icons/children-avatars-1/66/82_university_guy_party_boy_boy_student_home_kid_little_angle_hero-256.png',
  },
  {
    name: 'Pranavi Varma',
    avatar: 'https://cdn4.iconfinder.com/data/icons/world-children-s-day-bzzricon-flat/512/02_Student-256.png',
  },
  {
    name: 'Venkata Meghna',
    avatar: 'https://cdn4.iconfinder.com/data/icons/world-children-s-day-bzzricon-flat/512/02_Student-256.png',
  },
  {
    name: 'Rajesh Babu',
    avatar:
      'https://cdn0.iconfinder.com/data/icons/children-avatars-1/66/82_university_guy_party_boy_boy_student_home_kid_little_angle_hero-256.png',
  },
  {
    name: 'Kavya Nair',
    avatar: 'https://cdn4.iconfinder.com/data/icons/world-children-s-day-bzzricon-flat/512/02_Student-256.png',
  },
  {
    name: 'Madhavi Latha',
    avatar: 'https://cdn4.iconfinder.com/data/icons/world-children-s-day-bzzricon-flat/512/02_Student-256.png',
  },
  {
    name: 'Vinay Teja',
    avatar:
      'https://cdn0.iconfinder.com/data/icons/children-avatars-1/66/82_university_guy_party_boy_boy_student_home_kid_little_angle_hero-256.png',
  },
  {
    name: 'Suresh Kumar',
    avatar:
      'https://cdn0.iconfinder.com/data/icons/children-avatars-1/66/82_university_guy_party_boy_boy_student_home_kid_little_angle_hero-256.png',
  },
];

const generateLeaderboard = (data: any[]) => {
  const updatedData = data.map((user) => ({
    ...user,
    coins: Math.floor(Math.random() * 91 + 10) * 5,
  }));
  updatedData.sort((a, b) => b.coins - a.coins);
  return updatedData.map((user, index) => ({
    ...user,
    position: index + 1,
  }));
};

const mockdata: mockDetails[] = generateLeaderboard(mockUsers);

export const LeaderboardContainer: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [leaderBoardData, setLeaderBoardData] = useState<{ response: LeaderBoardUserItem[] }>({ response: [] });
  const [isEmpty, setIsEmpty] = useState(true);
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const [searchParams, setSearchParams] = useSearchParams();
  const { state, getLocalStorage } = useLocalStorage();
  const [leaderBoardUserDetails, setLeaderBoardUserDetails] = useState<LeaderBoardUserDetails>({
    userName: '',
    userScore: 0,
  });
  console.log(leaderBoardData);

  const queryParams = useMemo(
    () => ({
      magazineId: searchParams.get('id') || '0',
      startDate: searchParams.get('startDate') || Date.now().toString(),
      endDate: searchParams.get('endDate') || Date.now().toString(),
      grade: searchParams.get('grade') || '4',
      tenantId: state.tenantId || '1',
    }),
    [searchParams, state.tenantId]
  );

  const fetchLeaderboardData = async () => {
    if (!queryParams.tenantId || !queryParams.startDate || !queryParams.endDate) {
      return;
    }

    try {
      setIsLoading(true);

      if (state.phoneNumber) {
        let countryCode = getLocalStorage(COUNTRY_CODE_KEY);
        if (!countryCode) {
          countryCode = 'IN';
        }
        const phoneNumberWithCountryCode = `${getCodeForCountry(countryCode!)}${state.phoneNumber}`;
        const result = await yellowOwlApi.getUserDetails(phoneNumberWithCountryCode);
        if (result) {
          setLeaderBoardUserDetails({
            userName: result?.data?.userName ?? '',
            userScore: result?.data?.userScore ?? 0,
          });
        }
      }

      const result = await yellowOwlApi.getLeaderboardForTenant(
        queryParams.tenantId,
        queryParams.startDate,
        queryParams.endDate,
        queryParams.grade,
        queryParams.magazineId
      );

      if (result.data && (result.data as any).response) {
        const da: LeaderBoardUserItem[] = (result.data as any).response;
        setLeaderBoardData({ response: da });
        setIsEmpty(false);
      } else {
        setLeaderBoardData({ response: [] });
        setIsEmpty(true);
      }

      setHasError(false);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  // const userData = leaderBoardData.response;

  useEffect(() => {
    fetchLeaderboardData();
  }, [queryParams]);

  return (
    <LeaderBoardPage
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      startDate={queryParams.startDate}
      endDate={queryParams.endDate}
      grade={queryParams.grade}
      isLoading={isLoading}
      hasError={hasError}
      isEmpty={isEmpty}
      leaderBoardUserDetails={leaderBoardUserDetails}
      userData={mockdata}
      // userData={userData}
      acquiredBadges={AcquiredBadges}
      badgesToGet={BadgesToGet}
    />
  );
};
