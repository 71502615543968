import { useContext, useCallback } from 'react';
import { YellowOwlContext } from '../context/YellowOwlContext';
import { WritableUserEventEventDeviceEnum } from '@yellow-owl/client-sdk';
import { useAsync } from '../hooks/use-async';

enum EventType {
  LOGGEDIN = 'LOGGEDIN',
  LOGGEDOUT = 'LOGGEDOUT',
  LIBRARYPAGE = 'LIBRARYPAGE',
  TABLEOFCONTENTS = 'TABLEOFCONTENTS',
  ACTIVITYPAGE = 'ACTIVITYPAGE',
  PROFILEPAGE = 'PROFILEPAGE',
  PAYMENTSPAGE = 'PAYMENTSPAGE',
  ACTIVITY_COMPLETED = 'ACTIVITY_COMPLETED',
  MAGAZINE_COMPLETED = 'MAGAZINE_COMPLETED',
  PAYMENT_MADE = 'PAYMENT_MADE',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  FEEDBACK_SUBMITTED = 'FEEDBACK_SUBMITTED',
  APP_UNINSTALLED = 'APP_UNINSTALLED',
}

interface WritableUserEvent {
  userId: string;
  phoneNumber: string;
  dob: string;
  email: string;
  country: string;
  state: string;
  city: string;
  eventType: EventType;
  eventDevice: WritableUserEventEventDeviceEnum;
  eventData: EventData;
}

interface EventData {
  data: ActivityPageViewData | SubmitFeedbackData | TableOfContentsPageViewData;
}

interface ActivityPageViewData {
  type: 'ActivityPageViewData';
  categoryId: string;
  magazineId: string;
  activityId: string;
  skills: string[];
}

interface SubmitFeedbackData {
  type: 'SubmitFeedbackData';
  categoryId: string;
  magazineId: string;
  activityId: string;
  rating: string;
  skills: string[];
}

interface TableOfContentsPageViewData {
  type: 'TableOfContentsPageViewData';
  categoryId: string;
  magazineId: string;
}

const getAuthToken = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('Auth token is missing');
  }
  return token;
};

const getUserDetailsFromLocalStorage = () => {
  return {
    userId: localStorage.getItem('userId'),
    phoneNumber: localStorage.getItem('phoneNumber'),
    dob: localStorage.getItem('dateOfBirth'),
    email: localStorage.getItem('parentEmail'),
    country: localStorage.getItem('country'),
    state: localStorage.getItem('state'),
    city: localStorage.getItem('city'),
  };
};

const useSendEvent = () => {
  const yellowOwlApi = useContext(YellowOwlContext)!;

  const sendEventFn = useCallback(
    async ({
      eventType,
      eventData,
      eventDevice = WritableUserEventEventDeviceEnum.Web,
    }: {
      eventType: EventType;
      eventData: EventData['data'];
      eventDevice?: WritableUserEventEventDeviceEnum;
    }) => {
      const userDetails = getUserDetailsFromLocalStorage();
      const authToken = getAuthToken();

      if (!authToken) {
        throw new Error('Missing authentication token');
      }

      const requiredFields: (keyof typeof userDetails)[] = [
        'userId',
        'phoneNumber',
        'dob',
        'email',
        'country',
        'state',
        'city',
      ];

      requiredFields.forEach((field) => {
        if (!userDetails[field]) {
          throw new Error(`Missing required user detail: ${field}`);
        }
      });

      const body: WritableUserEvent = {
        userId: userDetails.userId!,
        phoneNumber: userDetails.phoneNumber!,
        dob: userDetails.dob!,
        email: userDetails.email!,
        country: userDetails.country!,
        state: userDetails.state!,
        city: userDetails.city!,
        eventType,
        eventDevice,
        eventData: { data: eventData },
      };

      try {
        const result = await yellowOwlApi.addUserEvents(body, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
        // console.log(`${eventType} event sent successfully`, result);
        return result;
      } catch (error) {
        console.error(`Error sending ${eventType} event:`, error);
        throw error;
      }
    },
    [yellowOwlApi]
  );

  const [state, sendEvent] = useAsync({ fn: sendEventFn });

  return { state, sendEvent };
};

export const useEventSenders = () => {
  const { sendEvent } = useSendEvent();

  const sendTableOfContentsEvent = useCallback(
    (categoryId: string, magazineId: string) => {
      sendEvent({
        eventType: EventType.TABLEOFCONTENTS,
        eventData: {
          type: 'TableOfContentsPageViewData',
          categoryId,
          magazineId,
        },
      });
    },
    [sendEvent]
  );

  const sendLibraryPageEvent = useCallback(
    (categoryId: string, magazineId: string) => {
      sendEvent({
        eventType: EventType.LIBRARYPAGE,
        eventData: {
          type: 'TableOfContentsPageViewData',
          categoryId,
          magazineId,
        },
      });
    },
    [sendEvent]
  );

  const sendActivityPageEvent = useCallback(
    (categoryId: string, magazineId: string, activityId: string, skills: string[]) => {
      sendEvent({
        eventType: EventType.ACTIVITYPAGE,
        eventData: {
          type: 'ActivityPageViewData',
          categoryId,
          magazineId,
          activityId,
          skills,
        },
      });
    },
    [sendEvent]
  );

  return {
    sendTableOfContentsEvent,
    sendLibraryPageEvent,
    sendActivityPageEvent,
  };
};
